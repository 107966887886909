<template>
  <div class="stage">
    <Search :searchOptions="options" @search="getSearch">
      <div class="btns">
        <el-button class="btn" type="primary" @click="modalShow"
          >新增</el-button
        >
      </div>
    </Search>
    <div class="stage_content">
      <Table
        :tableOptions="tableOptions"
        :tableData="tableData"
        :page="page"
        :total="total"
        @update:page="updatePage"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button type="text" @click="look('look', scope)">查看</el-button
            ><el-button type="text" @click="look('edit', scope)">编辑</el-button
            ><el-button type="text" style="color: red" @click="del(scope)"
              >删除</el-button
            >
          </div>
        </template>
      </Table>
    </div>
    <!-- 弹窗表单 -->
    <el-dialog
      title="志愿者任务"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
      destroy-on-close
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="140px"
        class="demo-ruleForm"
        :disabled="mode == 'look' ? true : false"
      >
        <el-form-item label="任务名称" prop="taskName">
          <el-input v-model="ruleForm.taskName" placeholder="请输入任务名称" />
        </el-form-item>
        <el-form-item label="积分奖励" prop="integralReward">
          <el-input
            v-model.number="ruleForm.integralReward"
            placeholder="请输入积分奖励"
          />
        </el-form-item>
        <el-form-item label="最大认领人数" prop="maxReceiveNumber">
          <el-input
            v-model.number="ruleForm.maxReceiveNumber"
            placeholder="请输入最大认领人数"
          />
        </el-form-item>
        <el-form-item label="认领时间" prop="enrollTime">
          <el-date-picker
            v-model="ruleForm.enrollTime"
            @change="handleTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd hh:mm:ss"
          />
        </el-form-item>
        <el-form-item label="任务时间" prop="activityTime">
          <el-date-picker
            @change="handleTimeActivity"
            v-model="ruleForm.activityTime"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            value-format="yyyy-MM-dd hh:mm:ss"
          />
        </el-form-item>

        <el-form-item label="任务地点" prop="taskAddress">
          <el-input
            v-model="ruleForm.taskAddress"
            placeholder="请输入任务地点"
          />
        </el-form-item>
        <el-form-item label="联系人" prop="contactName">
          <el-input v-model="ruleForm.contactName" placeholder="请输入联系人" />
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            placeholder="请输入联系电话"
          />
        </el-form-item>
        <el-form-item label="任务详情" prop="taskContent">
          <el-input
            v-model="ruleForm.taskContent"
            :rows="5"
            type="textarea"
            maxlength="300"
            show-word-limit
            placeholder="请输入任务详情"
          />
        </el-form-item>
      </el-form>
      <Table
        v-if="mode == 'look'"
        :tableOptions="detailTableOptions"
        :tableData="detailTableData"
        :pagination="false"
      >
        <template v-slot:action="{ scope }">
          <div class="btns">
            <el-button
              type="text"
              :disabled="scope.voucherSubmitTime == '已提交' ? false : true"
              @click="getAuditDetail(scope)"
              >查看</el-button
            >
          </div>
        </template>
      </Table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="primary"
          :disabled="mode == 'look' ? true : false"
          @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 弹窗表单 -->
    <el-dialog
      title="审核"
      :visible.sync="dialogVisibles"
      width="40%"
      :before-close="handleCloses"
      destroy-on-close
    >
      <el-form
        :model="ruleForms"
        ref="ruleForms"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="用户名称">
          <el-input v-model="ruleForms.userName" disabled />
        </el-form-item>
        <el-form-item label="联系电话">
          <el-input v-model="ruleForms.phone" disabled />
        </el-form-item>
        <el-form-item label="提交时间">
          <el-input v-model="ruleForms.voucherSubmitTime" disabled />
        </el-form-item>
        <el-form-item label="提交备注">
          <el-input v-model="ruleForms.voucherContent" disabled />
        </el-form-item>
        <el-form-item label="提交图片">
          <div class="blockss">
            <el-image
              v-for="item in ruleForms.voucherPicture"
              :key="item"
              style="
                width: 100px;
                height: 100px;
                margin-left: 6px;
                margin-top: 6px;
              "
              :src="item"
              :preview-src-list="srcList"
              @click="handleImage(item)"
            />
          </div>
        </el-form-item>
        <el-form-item label="审核结果">
          <el-radio-group v-model="ruleForms.auditingStatus">
            <el-radio :label="1">通过</el-radio>
            <el-radio :label="2">驳回</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloses">取 消</el-button>
        <el-button type="primary" @click="submitForms('ruleForms')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Search from "../../components/Search.vue";
import Table from "../../components/Table.vue";
export default {
  components: {
    Search,
    Table,
  },
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      mode: "add",
      modes: "add",
      page: {
        pageNum: 1,
        pageSize: 10,
      },
      total: 0,
      options: [
        {
          type: "input",
          placeholder: "任务名称",
          prop: "name",
        },
        {
          type: "time",
          start: "任务开始时间",
          end: "任务结束时间",
          prop: "times",
        },
      ],
      tableData: [],
      tableOptions: [
        {
          label: "任务名称",
          prop: "taskName",
        },
        {
          label: "积分奖励",
          prop: "integralReward",
        },
        {
          label: "最大认领人数",
          prop: "maxReceiveNumber",
        },
        {
          label: "报名人数",
          prop: "receiveSum",
        },
        {
          label: "任务时间",
          prop: "times",
        },
        {
          label: "发布人",
          prop: "userName",
        },
        {
          label: "发布时间",
          prop: "createTime",
        },
      ],
      detailTableOptions: [
        {
          label: "用户名称",
          prop: "userName",
        },
        {
          label: "电话号码",
          prop: "phone",
        },
        {
          label: "提交状态",
          prop: "voucherStatus",
        },
        {
          label: "审核状态",
          prop: "auditingStatus",
          enum: {
            1: "通过",
            2: "驳回",
            0: "未审核",
          },
        },
      ],
      detailTableData: [],
      ruleForm: {
        taskName: "",
        integralReward: "",
        taskStartTime: "",
        taskEndTime: "",
        receiveStartTime: "",
        receiveEndTime: "",
        taskTime: "", // 临时变量
        receiveTime: "", // 临时变量
        taskAddress: "",
        contactPhone: "",
        contactName: "",
        taskContent: "",
        activityTime: "", //临时变量
        enrollTime: "",
        receiveList: [],
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        integralReward: [
          { required: true, message: "请输入积分奖励", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        maxReceiveNumber: [
          { required: true, message: "请输入最大认领人数", trigger: "blur" },
          { type: "number", message: "请输入数字" },
        ],
        activityTime: [
          { required: true, message: "请选择任务时间", trigger: "blur" },
        ],
        enrollTime: [
          { required: true, message: "请选择认领时间", trigger: "blur" },
        ],
        taskAddress: [
          { required: true, message: "请输入任务地点", trigger: "blur" },
          { min: 1, max: 50, message: "最多50个字", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入联系人", trigger: "blur" },
          { min: 1, max: 20, message: "最多50个字", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "change" },
          {
            type: "string",
            pattern: /^1[3-9]\d{9}$/,
            message: "请输入正确的手机号",
            trigger: "blur",
          },
          // { type: 'number', message: '请输入数字' },
        ],
        taskContent: [
          { required: true, message: "请输入任务详情", trigger: "blur" },
        ],
      },
      id: null,
      ruleForms: {},
      srcList: [],
    };
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleImage(val) {
      this.srcList = [val];
    },
    look(type, row) {
      this.mode = type;
      this.id = row.id;
      // this.ruleForm = Object.assign(
      //   {},
      //   {
      //     ...row,
      //     enrollTime: row.receiveStartTime
      //       ? [row.receiveStartTime, row.receiveEndTime]
      //       : [],
      //     activityTime: row.taskStartTime
      //       ? [row.receiveStartTime, row.taskEndTime]
      //       : [],
      //   }
      // );
      this.getDetails();
      this.dialogVisible = true;
    },
    async getDetails() {
      const { flag, data } = await this.$get("/xng/backed/task/selectOneById", {
        id: this.id,
      });
      if (flag) {
        this.ruleForm = {
          ...data,
          enrollTime: data.receiveStartTime
            ? [data.receiveStartTime, data.receiveEndTime]
            : [],
          activityTime: data.taskStartTime
            ? [data.receiveStartTime, data.taskEndTime]
            : [],
        };
        this.detailTableData = data.receiveList.map((item) => {
          return {
            ...item,
            voucherStatus: item.voucherSubmitTime ? "已提交" : "未提交",
          };
        });
      }
    },
    getAuditDetail(row) {
      console.log(row);
      this.ruleForms = Object.assign(
        {},
        {
          ...row,
          voucherPicture: row.voucherPicture
            ? JSON.parse(row.voucherPicture)
            : [],
        }
      );
      this.dialogVisibles = true;
    },
    del(row) {
      this.$confirm("此操作将删除该信息, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const { flag, messgae } = await this.$del(
            `/xng/backed/task/delTaskInfo?id=${row.id}`
          );
          if (flag) {
            this.loadData();
            this.$message({
              type: "success",
              message: "删除成功!",
            });
          } else {
            this.$message, error(messgae);
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    getSearch(val) {
      const params = {
        pageNum: 1,
        pageSize: 10,
        ...val,
        startTime: val.times ? val.times[0] : "",
        endTime: val.times ? val.times[1] : "",
      };
      delete params.times;
      this.page = params;
      this.loadData();
    },
    // 加载数据
    async loadData() {
      const { flag, data } = await this.$get(
        "/xng/backed/task/selectPageListByCondition",
        this.page
      );
      if (flag) {
        this.total = data.total;
        this.tableData = data.rows.map((item) => {
          return {
            ...item,
            times: item.taskStartTime + " 至 " + item.taskEndTime,
          };
        });
      }
    },
    modalShow() {
      this.dialogVisible = true;
    },
    updatePage(val) {
      this.page.pageNum = val;
      this.loadData();
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisible = false;
          this.ruleForm = {};
          this.mode = "add";
        })
        .catch((_) => {});
    },
    handleCloses(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.dialogVisibles = false;
          this.ruleForms = {};
          this.modes = "add";
        })
        .catch((_) => {});
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          // console.log(this.ruleForm);
          const params = {
            ...this.ruleForm,
            taskStartTime: this.ruleForm.activityTime[0],
            taskEndTime: this.ruleForm.activityTime[1],
            receiveStartTime: this.ruleForm.enrollTime[0],
            receiveEndTime: this.ruleForm.enrollTime[1],
          };
          delete params.activityTime;
          delete params.enrollTime;
          if (this.ruleForm.id) {
            const { flag } = await this.$post(
              "/xng/backed/task/updateTaskInfo",
              params
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "编辑成功",
                type: "success",
              });
            } else {
              this.$message.error("编辑失败");
            }
          } else {
            const { flag } = await this.$post(
              "/xng/backed/task/addTaskInfo",
              params
            );
            if (flag) {
              this.loadData();
              this.dialogVisible = false;
              this.$message({
                message: "新增成功",
                type: "success",
              });
            } else {
              this.$message.error("新增失败");
            }
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 判断报名时间比活动时间早
    handleTime(val) {
      if (val) {
        if (this.ruleForm?.activityTime?.length) {
          if (
            this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[0] ||
            this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[1]
          ) {
            ElMessage.error("认领开始时间不能晚于任务开始时间");
            this.ruleForm.enrollTime = [];
          }
          if (
            this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[0] ||
            this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[1]
          ) {
            ElMessage.error("认领结束时间不能晚于任务结束时间");
            this.ruleForm.enrollTime = [];
          }
        }
      }
    },
    // 开始时间比领取时间晚且不相等
    handleTimeActivity(val) {
      // console.log(val);
      if (val) {
        if (this.ruleForm.enrollTime?.length) {
          if (
            this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[0] ||
            this.ruleForm?.enrollTime[0] > this.ruleForm?.activityTime[1]
          ) {
            ElMessage.error("任务开始时间不能晚于认领开始时间");
            this.ruleForm.activityTime = [];
          }
          if (
            this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[0] ||
            this.ruleForm?.enrollTime[1] > this.ruleForm?.activityTime[1]
          ) {
            ElMessage.error("任务结束时间不能晚于认领结束时间");
            this.ruleForm.activityTime = [];
          }
        }
      }
    },
    // 提交审核
    async submitForms() {
      const params = {
        ...this.ruleForms,
        voucherPicture: JSON.stringify(this.ruleForms.voucherPicture),
        taskId: this.ruleForm.id,
      };
      const { flag, message } = await this.$post(
        "/xng/backed/task/auditingTaskVoucher",
        params
      );
      // console.log(params);
      if (flag) {
        this.getDetails();
        this.dialogVisibles = false;
        this.$message({
          type: "success",
          message,
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.stage {
  width: 100%;
  .btns {
    padding: 0 10px;
  }
  .stage_content {
    width: 100%;
  }
}
</style>
